import { Roles } from 'types'
import { useAppSelector } from 'utils'

/**
 * Roles Hook
 *
 * Add roles logic to the app here, based on cerbere permissions or other role-based access control
 *
 * @returns rolesLoaded: boolean, hasAccess: boolean
 */
export const useRoles = () => {
  const { appPermissions, isLoading, isLogged } = useAppSelector(state => state.user)
  const roles = appPermissions.filter((role: string) => role.includes('miki::'))

  const hasAccess = roles.includes(Roles.ACCESS)
  const isAdmin = roles.includes(Roles.ADMIN)

  return {
    rolesLoaded: isLogged && !isLoading,
    roles,
    hasAccess,
    isAdmin,
  }
}

import sncflogo from 'assets/logos/sncflogo.svg'
import d2dlogo from 'assets/logos/d2dlogo.svg'
import AccountMenu from './account/AccountMenu'

import './TopBar.scss'

type Props = {
  appName: string;
}

export default function TopBar({ appName }: Props) {
  return (
    <header>
      <div className="logos">
        <img alt={appName} src={sncflogo} />
        <img alt={appName} src={d2dlogo} />
        <span>{appName}</span>
      </div>
      <div className="separator" />
      <AccountMenu />
    </header>
  )
}

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import terms from 'assets/terms'
import { RequestSignal } from 'services'
import { Button, ModalSignal, ToolsWrapper, RequestModal } from 'components'
import Requests from './Requests'

import './HomePage.scss'

export default function HomePage() {
  const requests = RequestSignal.value
  const handleNewRequest = () => {
    ModalSignal.value = <RequestModal />
  }

  return (
    <div id="home" className="page with-toolbar">
      <ToolsWrapper>
        <Button
          text={terms.Pages.Home.newRequest}
          icon={<PlusIcon />}
          onClick={handleNewRequest}
        />
      </ToolsWrapper>
      {!requests.length && (
        <p className="no-requests" title="salut">
          {terms.Pages.Home.noRequests}
        </p>
      )}
      {requests.length && (
        <Requests />
      )}
    </div>
  )
}

import moment from 'moment'
import terms from 'assets/terms'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download-file.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { RequestSignal } from 'services'
import { Button, ButtonSize, ButtonStyle } from 'components'

import './Requests.scss'

export default function Requests() {
  const requests = RequestSignal.value

  return (
    <div className="requests hide-scroll">
      <div className="mocked">MOCKED DATA</div>
      <div className="list">
        <div className="head request">
          <p className="name">{terms.Pages.Home.requests.name}</p>
          <p className="status">{terms.Pages.Home.requests.status}</p>
          <p className="date">{terms.Pages.Home.requests.lastUpdate}</p>
          <div className="actions-status" />
          <div className="actions-request" />
        </div>
        {requests.map(request => (
          <div key={request.id} className="request">
            <Button
              style={ButtonStyle.borderLess}
              text={terms.Pages.Home.requests.excelDownload}
              icon={<DownloadIcon />}
            />
            <p className="name">{request.request_name}</p>
            <p className="status">{request.status}</p>
            <p className="date">{moment(request.creation_date).format('DD/MM/YYYY')}</p>
            <div className="actions-status">
              <Button
                text={terms.Pages.Home.requests.import}
                size={ButtonSize.medium}
              />
            </div>
            <div className="actions-request">
              <Button
                text=""
                style={ButtonStyle.borderLess}
                icon={<DownloadFileIcon />}
              />
              <Button
                text=""
                style={ButtonStyle.borderLess}
                icon={<DeleteIcon />}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

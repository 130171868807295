/* eslint-disable react/no-danger */
import { useState } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import terms from 'assets/terms'
import { getFolderName, getFolderSize } from 'services'
import { Button, ButtonSize, ButtonStyle, Loader, RequestModal } from 'components'
import { ModalSignal } from '../ModalWrapper'

import './RequestModal.scss'

type Props = {
  files: File[]
}

export default function RequestUpload({ files }: Props) {
  const [loading, setLoading] = useState(true)

  const handleClose = () => {
    setLoading(false)
    ModalSignal.value = undefined
  }

  const handleCancel = () => {
    ModalSignal.value = <RequestModal />
  }

  return (
    <>
      <h3>
        {terms.Modals.Request.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p
        className="modal-info"
        dangerouslySetInnerHTML={{
          __html: loading ? terms.Modals.Request.infoUpload : terms.Modals.Request.infoSuccess,
        }}
      />
      <div className="folder-info">
        {loading ? <Loader variant="small" /> : <SuccessIcon />}
        <div>
          <b>{getFolderName(files)}</b>
          <span>{`${getFolderSize(files)} MB`}</span>
        </div>
      </div>
      {loading ? (
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleCancel}
        />
      ) : (
        <Button
          size={ButtonSize.medium}
          text={terms.Common.validate}
          onClick={handleClose}
        />
      )}
    </>
  )
}

import { signal } from '@preact/signals-react'
import { Request } from 'types'

const requestsMock: Request[] = [
  {
    id: '1fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'Dossier 1234',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 10,
    status: 'En attente EP modifié',
    user: 'string',
  },
  {
    id: '2fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'Dossier 4321',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 222,
    status: 'En attente',
    user: 'string',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'Doc_dddd2',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 93,
    status: 'Validé',
    user: 'string',
  },
  {
    id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'un autre document au titre à rallonge',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 0,
    status: 'Erreur de traitement',
    user: 'string',
  },
  {
    id: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'string',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 2,
    status: 'Un autre status',
    user: 'string',
  },
  {
    id: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'string',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 2,
    status: 'Un autre status',
    user: 'string',
  },
  {
    id: '7fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'string',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 2,
    status: 'Un autre status',
    user: 'string',
  },
  {
    id: '8fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'string',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 2,
    status: 'Un autre status',
    user: 'string',
  },
  {
    id: '9fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'string',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 2,
    status: 'Un autre status',
    user: 'string',
  },
  {
    id: '10fa85f64-5717-4562-b3fc-2c963f66afa6',
    request_name: 'string',
    creation_date: '2024-09-12T09:41:49.775Z',
    last_action: '2024-09-12T09:41:49.775Z',
    document_count: 2,
    status: 'Un autre status',
    user: 'string',
  },
]

export const RequestSignal = signal<Request[]>(requestsMock)

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { ChangeEvent } from 'react'

export const checkFileSize = (file: File, maxMb: number): boolean => (
  file.size <= maxMb * 1024 * 1024
)

export const getFolderSize = (files: File[]): number => (
  Math.round(files.reduce((acc, file) => acc + file.size, 0) / 1024 / 1024)
)

export const getFolderName = (files: File[]): string => {
  const folderName = files[0].name.split('/')[1]
  return folderName
}

export const renameFile = (file: File, newName: string): File => file.name !== '.DS_Store' && (
  new File([file], newName, { type: file.type, lastModified: file.lastModified })
)

export const isFolder = (item: DataTransferItem): boolean => {
  const entry = item.webkitGetAsEntry()
  return entry && entry.isDirectory
}

export const processDroppedFiles = async (items: DataTransferItemList): Promise<File[]> => {
  const files: File[] = []

  const traverseDirectory = async (entry: FileSystemEntry, path: string): Promise<void> => {
    if (entry.isFile) {
      await new Promise<void>(resolve => {
        (entry as FileSystemFileEntry).file(file => {
          const renamedFile = renameFile(file, `${path}/${file.name}`) // Rename the file to include the path
          files.push(renamedFile)
          resolve()
        })
      })
    } else if (entry.isDirectory) {
      const reader = (entry as FileSystemDirectoryEntry).createReader()

      const readAllEntries = async (): Promise<void> => new Promise((resolve, reject) => {
        const readEntries = () => {
          reader.readEntries(async entries => {
            if (entries.length === 0) {
              resolve() // No more entries, resolve the promise
            } else {
              for (const e of entries) {
                await traverseDirectory(e, `${path}/${entry.name}`) // Traverse the subdirectories
              }
              readEntries() // Keep reading until all entries are processed
            }
          }, reject)
        }
        readEntries()
      })

      await readAllEntries()
    }
  }

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i].webkitGetAsEntry()
    if (item) {
      await traverseDirectory(item, '') // Start with an empty path
    }
  }

  return files.filter(Boolean)
}

export const processInputFiles = (event: ChangeEvent<HTMLInputElement>) => (
  Array.from(event.target.files).map(file => renameFile(file, `/${file.webkitRelativePath}`)).filter(Boolean)
)

/* eslint-disable react/no-danger */
import { ChangeEvent, DragEvent, useEffect, useRef } from 'react'
import terms from 'assets/terms'
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg'
import { ReactComponent as FileIcon } from 'assets/icons/file.svg'
import { checkFileSize, isFolder, processDroppedFiles, processInputFiles } from 'services'
import { ToastLevel, ToastSignal } from 'components/toast/ToastMessage'

import './DropUpload.scss'

type Props = {
  onError: (files?: File[]) => void
  onUploadReady: (files: File[]) => void
}

export default function DropUpload({ onError, onUploadReady }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.setAttribute('directory', '')
      fileInputRef.current.setAttribute('webkitdirectory', '')
    }
  }, [fileInputRef])

  const handleError = (error: string, files?: File[]) => {
    if (files?.length) onError(files)
    ToastSignal.value = {
      message: error,
      severity: ToastLevel.ERROR,
    }
  }

  const handleUpload = async (files: File[]) => {
    if (!files.length) return handleError(terms.Messages.Upload.noFilesFound)
    if (files.some(file => !checkFileSize(file, 250))) return handleError(terms.Messages.Upload.fileSizeLimit, files)
    return onUploadReady(files)
  }

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const { items } = event.dataTransfer
    if (items.length > 1 || !isFolder(items[0])) return handleError(terms.Messages.Upload.oneFolderOnly)
    const files = await processDroppedFiles(items)
    return handleUpload(files)
  }

  const handleInputFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = processInputFiles(event)
    return handleUpload(files)
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className="drop-upload">
      <div
        className="drop-zone flex-column-center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleInputFileChange}
          style={{ display: 'none' }}
        />
        <div className="icon">
          <FileIcon />
          <UploadIcon />
        </div>
        <p
          className="drop-info"
          dangerouslySetInnerHTML={{ __html: terms.Components.Upload.info('dossier') }}
        />
      </div>
    </div>
  )
}

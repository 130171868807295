import './ToolsWrapper.scss'

type Props = {
  children: React.ReactNode
}

export default function ToolsWrapper({ children }: Props) {
  return (
    <div className="tools-bar">
      {children}
    </div>
  )
}
